import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu  from './menu';
import Footer from './adminfooter';
import ClassTab from './classtab';
import { Table } from 'react-bootstrap';
import { Util } from '../../api/util';
import { liststudent,importstudent} from '../../store/editelearning/actions';
import { queryclear} from  '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { createPage } from '../tab/pagetab';
import { StudentType } from '../../api/define';

const EditElearning = () => {
  const [init,setInit] = useState(0)
  const [items,setItems] = useState<any>({})
  const [studentType,setStudentType] = useState<StudentType>(StudentType.LIST);
  const [loading,setLoading] = useState(false)
  const [updating,setUpdating] = useState(false)
  const [page,setPage] = useState(0)
  const [limit,setLimit] = useState(10)
  
  const [success,setSuccess] = useState(false)
  
  const [error, setError] = useState('');
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory(); 
  let { id } = useParams<{ id: string}>();
  let { class_course_id } = useParams<{ class_course_id: string}>();
  
  
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
        let res = store.getState().SearchReducer;
        if (res.isLoading) {
            setError('');
            setLoading(true);
        } else if (res.isLoaded) {
            setError('');
            setLoading(false);
            if (res.data!=null) {
                if (res.table==='list_student') {
                    setInit(1);
                    setStudentType(res.data.studentType);
                    setItems(res.data);
                    setPage(0);
                } 
            } 
        } else if (res.isUpdating) {
            setError('');
            setUpdating(true);
        } else if (res.isUpdated) {
            if (res.table==='import_student') {
                setError('');
                setUpdating(false);
                setPage(0);
                dispatch(liststudent(parseInt(id),parseInt(class_course_id),0,10));
                alert({ message: 'Saved', type: 'success' });
                setSuccess(true);
            } 
            dispatch(queryclear());
        } else if (res.isFailed) {
                if ((res.data.success!==undefined) || (res.data.importSuccess!==undefined)) {             
                
                setItems(res.data);
                alert({ message: res.data.popup??'Error', type: 'error' });
                window.scrollTo(0, 0);
                } 
              setError(res.data.message);
              setLoading(false);
              setUpdating(false);
            dispatch(queryclear());
        }
      })
    return unsubscribe;
   }, [page,limit])

   useEffect(() => {
    dispatch(queryclear());
    dispatch(liststudent(parseInt(id),parseInt(class_course_id),0,10));
   }, [])

   const validateTab = () => {
    return true;
  }

  const checkAssignment = () =>{
    var courseList=(items.course===undefined)?[]:items.course;
    for (var i=0;i<courseList.length;i++) {
        var c=courseList[i];
        if ((c.isAssignment!==null) && (c.isAssignment!==undefined) && (c.isAssignment===true)) return true;
    }
    return false;
 }

  const checkStudentList = () => {
    return (items.studentType===StudentType.LIST);
  }

const handleSubmit = () => {
    dispatch(queryclear());
    dispatch(importstudent(parseInt(id),parseInt(class_course_id)));
}

const handleClose = () => {
    history.push("/admin/class/"+id);
}

const handleLimit = (l: number) => {
    setLimit(l);
 }

const handlePage = (l: number) => {
    setPage(l);
}

const handleStudent=()=>{
    history.push("/admin/class/"+id+"/student");
}

const handleDoc=()=>{
    history.push("/admin/class/"+id+"/doc");
}

const handleHistory=()=>{
    history.push("/admin/class/"+id+"/history");
}

const handleAssignment=()=>{
    history.push("/admin/class/"+id+"/assignment");
}

const handleResult=()=>{
    history.push("/admin/class/"+id+"/result");
}

const handleSurvey=()=>{
    history.push("/admin/class/"+id+"/survey");
}

const getTotalItem = () => {
    if (items.studentList===undefined) return 0;
    if (items.studentType!==studentType) return 0;
    return items.studentList.length;
}

const getTotalPage = () => {
    if (items.studentList===undefined) return 0;
    if (items.studentType!==studentType) return 0;
    return Math.ceil(items.studentList.length/limit);
}

const getTotalImport = () => {
    return items.totalImport;
}

const getTotalError = () => {
    return items.totalError;
}

return (<>
    <MainMenu>
    <div>
    <div className="query-wrapper">
    <br/>
    <div className="yellow-button" style={{textAlign:"left" ,borderTopLeftRadius:"30px", borderTopRightRadius:"30px", height:"70px",paddingTop:"15px",paddingLeft:"15px"}}><h4 className="header">รอบการเรียน</h4></div>
    { (init===1) &&
    <>
    <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px',borderBottomRightRadius: '5px',paddingLeft:"20px",paddingRight:"20px",paddingBottom:"20px",textAlign:"left"}}>
        <div className="row">
            <div className="col-4">                           
                <label className="col-form-label">โครงการ</label><br/>
                <input className="form-control" type="text" value={items.code+":"+items.project} readOnly={true}/>
            </div>
            <div className="col-1">                          
                <label className="col-form-label">รอบ</label><br/>
                <input className="form-control" type="text" value={items.session} readOnly={true}/>
            </div>
            <div className="col-2">                          
                <label className="col-form-label">วันที่เริ่ม</label>
                <input className="form-control" type="text" value={Util.datetostr(new Date(items.fromDate))} readOnly={true}/>
            </div>
            <div className="col-2">                           
                <label className="col-form-label">วันที่สิ้นสุด</label>
                <input className="form-control" type="text" value={Util.datetostr(new Date(items.toDate))} readOnly={true}/>
            </div>
            <div className="col-3">
                <label className="col-form-label">ฝ่ายผู้รับผิดชอบโครงการ</label><br/>
                <input className="form-control" type="text" value={items.organizer} readOnly={true}/>
            </div>
        </div>
    </div>
    <br/>
    { ClassTab(1,validateTab(),checkAssignment(),checkStudentList(),null,(validateTab())?handleStudent:null,(validateTab())?handleDoc:null,(validateTab())?handleAssignment:null,(validateTab())?handleHistory:null,(validateTab())?handleResult:null,(validateTab())?handleSurvey:null) }
    <div style={{background:"white",padding:"40px",borderRadius:"5px"}}>
            <div className="row">
                <div className="col-6" style={{justifyContent:"left",textAlign:"left"}}>
                    จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ        
                </div>
                <div className="col-6 row" style={{justifyContent:"right",textAlign:"right",paddingRight:"0px"}}>
                    <div style={{textAlign:"right",marginTop:"auto",marginBottom:"auto"}}>จำนวนรายการต่อหน้า</div>&nbsp;
                    <select className="form-control" style={{width:"80px"}} name="page" value={limit} onChange={e=>handleLimit(parseInt(e.target.value))}>
                            <option value={getTotalItem()}>All</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                    </select>
                </div>
            </div>
            <br/>
            <Table striped hover style={{borderRadius:"5px",borderCollapse: "collapse"}}>  
                <thead className="black-button">
                    <tr>
                        <th style={{ verticalAlign: "top"}}>ลำดับ</th>
                        <th className='text-left' style={{ verticalAlign: "top"}}>ชื่อ-นามสกุล</th>
                        <th className='text-left' style={{ verticalAlign: "top"}}>e-Mail</th> 
                        <th className='text-left' style={{ verticalAlign: "top"}}>ชื่อองค์กร</th>            
                        <th className='text-left' style={{ verticalAlign: "top"}}>สถานะ</th>
                    </tr>
                </thead>
                <tbody style={{fontSize:"24px"}}>
                    { (items.studentType===StudentType.LIST) && 
                        items.studentList.map((item: any, index: number) => {
                            if ((index>=page*limit) && (index<(page+1)*limit)) 
                            return <tr key={item.id}  className={(item.updated?"green":"")+" "+(((item.id===undefined) && (item.updated===false))?"blue":"")}>
                                    <td> {index+1}</td>
                                    <td className='text-left'> {item.firstName} - {item.lastName}</td>
                                    <td className='text-left'> {item.email} </td>
                                    <td className='text-left'> {item.partner} </td>
                                    <td className='text-left'> {item.status} </td>                                                                   
                                    </tr>
                     })}
                </tbody>
            </Table>
            <br/>
            <div className="row" style={{justifyContent:"center",position:"relative"}}>
                { createPage(page,getTotalPage(),handlePage) }
                <div className="col-5" style={{justifyContent:"left",textAlign:"left",position:"absolute",left:"0px"}}>
                    จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ <br/>
                    { ((items.importSuccess!==undefined) && (items.studentType===studentType)) && 
                    <>
                        <span className='green'>จำนวนนำเข้า {Util.integerWithCommas(getTotalImport())} รายการ </span><br/>
                        <span className='red'>จำนวนผิดพลาด {Util.integerWithCommas(getTotalError())} รายการ</span><br/>
                    </>
                    }
                </div>
                <br/><br/><br/><br/>
            </div>
    </div>
    </>
    }
    <br/>
    { ((init<1) || (loading) || (updating)) && (error==="") &&
        <div style={{ textAlign: "center",width:"1200px" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
    }
    { (error!=="") &&
        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
    }
    { (init===1) && (!loading) && (!success) && (!updating) &&
        <div style={{justifyContent:"right",textAlign:"right"}}>
            <button type="button" className="btn btn-outline-primary" style={{width:"120px"}}
                    onClick={() => handleClose()} >
                    Back
            </button>&nbsp;
            <button type="button" className="btn btn-success" style={{width:"120px"}}
                    onClick={() => handleSubmit()} >
                    Save
            </button>
        </div>
    }
    {  (success) && 
        <div style={{justifyContent:"center",textAlign:"center"}}>
        <button type="button" className="btn btn-outline-primary" style={{width:"120px"}}
                onClick={() => handleClose()} >
                Back
        </button>
        </div>
    }
    </div>
    <br/>
    <Footer/>
    </div>
    </MainMenu>
    <AlertContainer floatingTime={10000}/>
    </>)
}

export default EditElearning;