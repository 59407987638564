import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ClassAPI } from '../../api/class-api';
import { StudentAPI } from '../../api/student-api';
import { dispatchError } from '../search/actions'; 
import { StudentType } from '../../api/define';

export const getclass = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ClassAPI.getClass(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class",
            page: "edit_student",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_student",dispatch,error);
    })
};

export const liststudent = (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    StudentAPI.list(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_student",
            page: "edit_student",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_student",dispatch,error);
    })
};

export const savestudent = (classId: number,item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.save(classId,item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "list_student",
            page: "edit_student",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_student",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_student",dispatch,error);
    })
};

export const importstudent = (classId: number,studentType: StudentType,file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.import(classId,studentType,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "list_student",
            page: "edit_student",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_student",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_student",dispatch,error);
    })
};

